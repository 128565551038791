import request from 'network/request';

const baseURL = 'order/extend/purchase/cart/';

export function getTopUp() {
  return request({
    url: baseURL + 'fullAmount',
    method: 'get'
  });
}
export function getPurchaseOrderData() {
  return request({
    url: baseURL + 'po/overview',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function addPurchaseOrder(params) {
  return request({
    url: `${baseURL}add/${params.goodsCode}/${params.number}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function batchAddPurchaseOrder(params) {
  return request({
    url: baseURL + 'addAll',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
