<template>
  <section id="app">
    <router-view></router-view>
  </section>
</template>

<script>
  export default {
    name: 'App'
  }
</script>

<style>

</style>
