import {
  getToken,
  setToken,
  getPcLoginKey,
  setPcLoginKey,
  setUserName,
  getRegularFlag,
  setRegularFlag,
  getUnionFlag,
  setUnionFlag
} from 'utils/user.js';
import {
  phoneLogin,
  getSunCode,
  sunCodeGetToken,
  getUserInfo,
  getUserIdentity
} from 'api/user.js';

const getDefaultState = () => {
  return {
    token: getToken(),
    pcLoginKey: getPcLoginKey(),
    regularFlag: getRegularFlag(),
    unionFlag: getUnionFlag()
  };
};
const state = getDefaultState();

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_PCLOGINKEY: (state, pcLoginKey) => {
    state.pcLoginKey = pcLoginKey;
  },
  SET_REGULARFLAG: (state, regularFlag) => {
    state.regularFlag = regularFlag;
  },
  SET_UNIONFLAG: (state, unionFlag) => {
    state.unionFlag = unionFlag;
  }
};

const actions = {
  phoneLogin({commit}, userInfo) {
    const {loginSource, phoneNumber, verificationCode} = userInfo;
    return new Promise((resolve, reject) => {
      phoneLogin({
        loginSource,
        phone: phoneNumber.trim(),
        code: verificationCode.trim()
      }).then(res => {
        const {data} = res;
        commit('SET_TOKEN', data);
        setToken(data);
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  },
  getSunCode({commit}) {
    return new Promise((resolve, reject) => {
      getSunCode().then(res => {
        const {data} = res;
        commit('SET_PCLOGINKEY', data.pcLoginKey);
        setPcLoginKey(data.pcLoginKey);
        resolve(data.imgUrl);
      }).catch(err => {
        reject(err);
      });
    });
  },
  sunCodeGetToken({commit}) {
    return new Promise((resolve, reject) => {
      sunCodeGetToken().then(res => {
        const {data} = res;
        if(data.state === 2) {
          commit('SET_TOKEN', data.token);
          setToken(data.token);
        }
        resolve(data);
      }).catch(err => {
        reject(err);
      });
    });
  },
  getUserInfo() {
    return new Promise((resolve, reject) => {
      getUserInfo().then(res => {
        const {data} = res;
        setUserName(data.nickName);
        resolve();
      }).catch(err => {
        reject(err);
      });
    });
  },
  getUserIdentity() {
    return new Promise((resolve, reject) => {
      getUserIdentity().then(res => {
        const {data} = res;
        setRegularFlag(data.regularFlag);
        setUnionFlag(data.unionFlag);
        resolve();
      }).catch(err => {
        reject(err);
      });
    });
  }
};

export default {
  state,
  mutations,
  actions
}
