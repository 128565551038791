import request from 'network/request';

const baseURL = 'client/login/';

export function getVerificationCode(params) {
  return request({
    url: baseURL + 'sendPhoneCode',
    method: 'get',
    params
  });
}
export function phoneLogin(params) {
  return request({
    url: baseURL + 'validationCodePC',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function getSunCode() {
  return request({
    url: baseURL + 'pcLoginQRCode',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function sunCodeGetToken() {
  return request({
    url: baseURL + 'pcLoginToke',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function getUserInfo() {
  return request({
    url: baseURL + 'getMemberInfo',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function getUserIdentity() {
  return request({
    url: '/client/card/shop/info',
    method: 'get'
  });
}
