import axios from 'axios';
import {
  getToken,
  getPcLoginKey
} from 'utils/user.js';

export default function request(config) {
  // 创建axios实例对象
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 10000
  });
  // 请求拦截
  instance.interceptors.request.use(
    config => {
      config.headers['client_token'] = getToken() || '';
      config.headers['pCLoginKey'] = getPcLoginKey() || '';
      return config;
    },
    error => {
      console.log(error);
    }
  );
  // 响应拦截
  instance.interceptors.response.use(
    resolve => {
      if(resolve.data.code === 401) {
        this.$message.warning('用户登录已过期，请重新登陆！');
        location.href('/home');
      }
      return resolve.data;
    },
    error => {
      console.log(error);
    }
  );
  // 返回axios实例对象
  return instance(config);
};
