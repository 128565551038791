import {
  getPurchaseOrderData
} from 'api/purchaseOrder.js';

const getDefaultState = () => {
  return {
    applyWholesale: true,
    product: 0,
    piece: 0,
    fullAmount: 0,
    cartMoney: 0,
    difference: 0
  };
};
const state = getDefaultState();

const mutations = {
  SET_APPLYWHOLESALE: (state, applyWholesale) => {
    state.applyWholesale = applyWholesale;
  },
  SET_PRODUCT: (state, product) => {
    state.product = product;
  },
  SET_PIECE: (state, piece) => {
    state.piece = piece;
  },
  SET_FULLAMOUNT: (state, fullAmount) => {
    state.fullAmount = fullAmount;
  },
  SET_CARTMONEY: (state, cartMoney) => {
    state.cartMoney = cartMoney;
  },
  SET_DIFFERENCE: (state, difference) => {
    state.difference = difference;
  }
};

const actions = {
  getPurchaseOrderData({commit}) {
    return new Promise((resolve, reject) => {
      getPurchaseOrderData().then(res => {
        commit('SET_APPLYWHOLESALE', res.applyWholesale);
        commit('SET_PRODUCT', res.species);
        commit('SET_PIECE', res.piece);
        commit('SET_FULLAMOUNT', res.fullAmount);
        commit('SET_CARTMONEY', res.cartMoney);
        commit('SET_DIFFERENCE', res.fullAmount - res.cartMoney);
        resolve();
      }).catch(err => {
        reject(err);
      });
    });
  },
};

export default {
  state,
  mutations,
  actions
}
