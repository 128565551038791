import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    // 重定向
    {
      path: '',
      redirect: '/home'
    },
    // 登录页
    {
      name: 'login',
      path: '/login',
      component: () => import('views/login/index'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // 首页
    {
      name: 'home',
      path: '/home',
      component: () => import('views/home/index'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // 商品详情页
    {
      name: 'goodsDetail',
      path: '/goodsDetail',
      component: () => import('views/goodsDetail/index'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // 采购单
    {
      name: 'purchaseOrder',
      path: '/purchaseOrder',
      component: () => import('views/purchaseOrder/index'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // 订单结算页
    {
      name: 'settlementPage',
      path: '/settlementPage',
      component: () => import('views/settlementPage/index'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // 支付页
    {
      name: 'payPage',
      path: '/payPage',
      component: () => import('views/payPage/index'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // 个人中心
    {
      name: 'personalCenter',
      path: '/personalCenter',
      component: () => import('views/personalCenter/index'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // 订单详情
    {
      name: 'orderDetail',
      path: '/orderDetail',
      component: () => import('views/personalCenter/childrenPage/orderDetail/OrderDetail'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // 开票历史
    {
      name: 'invoiceHistory',
      path: '/invoiceHistory',
      component: () => import('views/personalCenter/childrenPage/invoiceCenter/InvoiceHistory'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // 开票
    {
      name: 'openInvoice',
      path: '/openInvoice',
      component: () => import('views/personalCenter/childrenPage/openInvoice/OpenInvoice'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // 开票详情
    {
      name: 'openInvoiceDetail',
      path: '/openInvoiceDetail',
      component: () => import('views/personalCenter/childrenPage/invoiceCenter/OpenInvoiceDetail'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // 服务协议
    {
      name: 'serviceAgreement',
      path: '/serviceAgreement',
      component: () => import('views/serviceAgreement/index'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // 大宗采购介绍
    {
      name: 'introducePage',
      path: '/introducePage',
      component: () => import('views/introducePage/index'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // 查看物流
    {
      name: 'seeLogistics',
      path: '/seeLogistics',
      component: () => import('views/personalCenter/childrenPage/seeLogistics/index'),
      meta: {
        title: '青葫芦批采商城'
      }
    },
    // H5页面
    {
      name: 'encyclopediaBritannica',
      path: '/fd/encyclopediaBritannica',
      component: () => import('views/fd/encyclopediaBritannica'),
      meta: {
        title: '防伪查询'
      }
    }
  ]
});
router.beforeEach((to, _, next) => {
  document.title = to.meta.title;
  next();
});

// 解决Vue Router多次点击报错Bug
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};

export default router;
