import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'lib-flexible/flexible';
import 'assets/css/normalize.css';
import 'assets/css/base.css';
import {
  Form,
  FormItem,
  Input,
  InputNumber,
  RadioGroup,
  Radio,
  Checkbox,
  Select,
  Option,
  Button,
  Table,
  TableColumn,
  Pagination,
  Dialog,
  Popover,
  Popconfirm,
  Upload
} from 'element-ui';
import {Message} from 'element-ui';
import {Loading} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Popover);
Vue.use(Popconfirm);
Vue.use(Upload);
Vue.prototype.$message = Message;
Vue.prototype.Loading = Loading;

Vue.config.productionTip = false;
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app');
