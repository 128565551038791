import Cookies from 'js-cookie';

const TokenKey = 'client_token';
const PcLoginKey = 'pcLoginKey';
const RegularFlagKey = 'regularFlag';
const UserNameKey = 'userName';
const UnionFlagKey = 'unionFlag'

export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function getPcLoginKey() {
  return Cookies.get(PcLoginKey);
}
export function setPcLoginKey(pcLoginKey) {
  return Cookies.set(PcLoginKey, pcLoginKey);
}
export function removePcLoginKey() {
  return Cookies.remove(PcLoginKey);
}
export function getRegularFlag() {
  return Cookies.get(RegularFlagKey);
}
export function setRegularFlag(regularFlag) {
  return Cookies.set(RegularFlagKey, regularFlag);
}
export function removeRegularFlag() {
  return Cookies.remove(RegularFlagKey);
}
export function getUserName() {
  return Cookies.get(UserNameKey);
}
export function setUserName(userName) {
  return Cookies.set(UserNameKey, userName);
}
export function getUnionFlag() {
  return Cookies.get(UnionFlagKey);
}
export function setUnionFlag(unionFlag) {
  return Cookies.set(UnionFlagKey, unionFlag);
}
export function removeUnionFlag() {
  return Cookies.remove(UnionFlagKey);
}
